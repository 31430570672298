import React from 'react';
import ReactGA from 'react-ga';
import Logo from './components/Logo';
import Contact from './components/Contact';

ReactGA.initialize('UA-57190855-1');
ReactGA.pageview(window.location.pathname);

const App: React.FC = () => (
  <div className="app">
    <div className="app__content">
      <Logo />
      <Contact />
    </div>
  </div>
);

export default App;
