import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';

const Contacts: React.FC = () => (
  <div className="contacts">
    <a className="contacts__link" href="mailto:info@codeflow.ee">
      <FontAwesomeIcon icon={faEnvelope} size="5x" />
    </a>
    <a className="contacts__link" href="https://www.linkedin.com/in/andretoodre" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faUser} size="5x" />
    </a>
  </div>
);

export default Contacts;
